var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fc030f6d6763be963e5b6333e0a1dedea00fe38b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Replay } from '@sentry/browser';
import { isAxiosErrorExactly } from './src/shared/utils';
import { isCancel } from 'axios';
import { CancelRouteChangeThrowable } from './src/hooks/useConfirmNavigation';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.ENV;
const SENTRY_RELEASE = process.env.SENTRY_RELEASE;
const SENTRY_DISABLED = process.env.SENTRY_DISABLED;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENV,
  release: SENTRY_RELEASE,
  tracesSampleRate: 0.2,
  beforeSend: (event, { originalException }) => {
    if (/(HeadlessChrome|Electron)/.test(navigator.userAgent)) return null;

    if (
      // NOTE: AxiosErrorはapi/index.tsで明示的にレポート済みのためここではレポートしない
      isAxiosErrorExactly(originalException) ||
      // NOTE: 下記2つはErrorBoundaryのSentry.withErrorBoundary()でレポートされるのを防ぐ
      isCancel(originalException) ||
      originalException instanceof CancelRouteChangeThrowable
    )
      return null;

    return event;
  },
  normalizeDepth: 5,
  enabled: !SENTRY_DISABLED,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded', // #763
    /^Error: Network Error$/, // #764
    /^Error: Request aborted$/, // #766
  ],
  replaysOnErrorSampleRate: ENV === 'production' ? 1 : 0,
  replaysSessionSampleRate: 0,
  integrations: [new Replay()],
});
